.current-events-page {
  flex-grow: 1;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 9999;

  .transition {
    height: 100%;
  }

  .header {
    height: 500px;
    padding-bottom: 20px;

    img {
      height: 100%;
    }
  }

  .event-detail {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin: 0 100px 0 130px;

    .event-date-time {
      padding-right: 50px;
      border-right: 2px solid $magenta;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: $magenta;
      z-index: 10;

      .day {
        font-size: 36px;
        line-height: 40px;
        font-weight: 400;
      }

      .date {
        font-size: 78px;
        line-height: 82px;
        font-weight: 900;
        white-space: nowrap;
      }

      .time {
        font-size: 36px;
        line-height: 40px;
        font-weight: 400;

        .from-to {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .event-type-description {
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      text-align: left;
      color: $magenta;
      z-index: 10;

      .type {
        font-size: 62px;
        line-height: 66px;
        font-weight: 700;
        padding-bottom: 20px;
      }

      .description {
        font-size: 32px;
        line-height: 36px;
        font-weight: 400;
        margin: inherit;
      }
    }
  }
}

.no-current-events-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;


  .header {
    text-align: center;
    height: 500px;
    padding-bottom: 20px;

    img {
      height: 100%;
    }
  }

  .no-current-events {
    font-size: 84px;
    line-height: 94px;
    font-weight: 700;
    color: $magenta;
    width: 100%;

    .text {
      width: 572px;
      text-align: center;
      margin: 0 auto;
    }
  }

  .transparent-image {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 55%;
    z-index: 1;

    img {
      width: auto;
      max-width: 1080px;
      height: auto;
      max-height: 720px;
      opacity: 0.7;
      border-radius: 50%;
      mask-image: radial-gradient(rgba(255,255,255,1) 30%, rgba(0, 0, 0, 0) 70%);
    }
  }
}

.what-is-magenta {
  position: absolute;
  bottom: 200px;
  left: 50px;
  color: $white;
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 10px;
  opacity: 0.5;
  z-index: 10;

  .line {
    padding-top: 20px;
    &:nth-child(2) {
      padding-left: 100px
    }
    &:nth-child(3) {
      padding-left: 50px
    }
  }
}
