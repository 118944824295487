@import '_asset/scss/_variables';
@import '_asset/scss/_fonts';

@import '_asset/scss/sideScreen/_side-screen';
@import '_asset/scss/sideScreen/_week-events';
@import '_asset/scss/sideScreen/_current-events';

@import '_asset/scss/mainEventStage/_main-event-stage';
@import '_asset/scss/mainCommercialStage/_main-commercial-stage';

@import '_asset/scss/_single-event';
@import '_asset/scss/_print';

html {
  font-family: 'TeleNeo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: $white;
  min-height: 100%;
  min-width: 100%;
}

body {
  min-height: 100%;
  min-width: 100%;
}

.transition {
  position: absolute;
  top: 0;
  width: 100%;
  height: inherit;
  z-index: 10;
}

.transparent-image {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 55%;
  z-index: 1;

  img {
    width: auto;
    max-width: 1080px;
    height: auto;
    max-height: 720px;
    opacity: 0.7;
    border-radius: 50%;
    mask-image: radial-gradient(rgba(255,255,255,1) 30%, rgba(0, 0, 0, 0) 70%);
  }
}

.loader-wrapper {
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.loader {
  border: 10px solid transparent;
  border-radius: 50%;
  border-top: 10px solid $magenta;
  width: 140px;
  height: 140px;
  -webkit-animation: spin 1.2s linear infinite; /* Safari */
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
  padding: 50px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
