.week-events-page {
  flex-grow: 1;
  text-align: center;

  .header {
    margin-bottom: 60px;
    padding: 60px;
    display: flex;
    flex-direction: row;

    .logo {
      width: 55%;
      padding: 0 60px;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    .title {
      font-size: 80px;
      line-height: 91px;
      font-weight: 700;
      color: $magenta;
      width: 50%;
      margin: 0 auto;
      text-align: left;
    }
  }
  .week-event {
    display: flex;
    flex-direction: row;
  }

  .transition {
    position: absolute;
    top: 480px;
    height: inherit;
    overflow: hidden;
    z-index: 1;
  }

  .column {
    width: 100%;
    height: 1300px;
    padding: 0 40px;

    .event {
      display: flex;
      flex-direction: row;
      font-size: 80px;
      width: 100%;
      padding: 40px 0;
      border-bottom: 2px solid $magenta;

      &:last-child {
        border-bottom: none;
      }

      .day-date {
        border-right: 2px solid $magenta;
        padding: 20px 20px 20px 0;
        text-align: right;
        min-width: 220px;

        .day {
          font-size: 24px;
          font-weight: 300;
        }
        .date {
          font-size: 30px;
          font-weight: 700;
        }
      }

      .event-name-time {
        padding-right: 20px;
        flex-grow: 1;
        text-align: right;
        display: flex;
        flex-direction: column;

        .name-time {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          &:last-child {
            flex-grow: 1;
            .name {
              padding: 0 15px 0;
            }
          }

          .name {
            width: 100%;
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            border-right: 2px solid $magenta;
            padding: 0 15px 10px;
          }

          .time {
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
            width: 100px;
            min-width: 100px;
            text-align: left;
            padding-left: 15px;
          }
        }
      }

      .zone {
        width: 180px;
        min-width: 180px;
        display: flex;
        justify-content: center;
        align-self: center;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .right-column {
    height: 1500px;
    padding-right: 0;
    padding-left: 80px;
  }
}

.no-week-events {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-self: center;
  height: 100%;

  .no-events {
    font-size: 76px;
    line-height: 82px;
    font-weight: 600;
    color: $magenta;
    width: 100%;

    .text {
      text-align: center;
      width: 70%;
      margin: 0 auto;
    }
  }
}
