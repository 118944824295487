.side-screen {
  height: 3840px;
  min-height: 3840px;
  width: 2160px;
  min-width: 2160px;
  display: flex;
  flex-wrap: wrap;

  .side-screen-layout {
    height: 1920px;
    min-height: 1920px;
    width: 1080px;
    min-width: 1080px;
    display: flex;
    flex-direction: column;
    position: relative;

    &.dots {
      // !!! the order of background and background-size MATTER !!!
      background: url("../../../_asset/img/dark-background.jpg") no-repeat $body-background;
      background-size: 100% 100%;
      overflow: hidden;

    }
    &.lines {
      background: url("../../../_asset/img/bg_lines.png") no-repeat $body-background;
      background-size: 100% 100%;
      overflow: hidden;
    }
  }
}

// footer in all screens
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 50px 50px 50px;

  &.spaced {
    justify-content: space-between;
  }

  .link {
    color: $magenta;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    padding-right: 50px;
  }

  .logo {
    width: 164px;
    height: auto;
    padding-left: 50px;
  }
}
