// colors
$body-background: #35011d;

$gray-dark: #5a5a5a;
$gray: #999999;
$gray-light: #d9d8d8;

$magenta: #F00081;
$turquoise: #00C2CB;

$white: #fbfbfb;
