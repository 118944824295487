.main-commercial-stage {
  height: 3840px;
  min-height: 3840px;
  width: 2160px;
  min-width: 2160px;
  background: url("../../../_asset/img/dark-background.jpg")  $body-background;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;

  .current-events-vertical-screen {
    height: inherit;
    min-height: inherit;
    width: inherit;
    min-width: inherit;
    background: url("../../../_asset/img/bg_lines.png")  $body-background;
    background-size: cover;
    overflow: hidden;
    /* this will hide the scrollbar in mozilla based browsers */
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    /* this will hide the scrollbar in internet explorers */
    -ms-overflow-style: none;

    .single-event {
      padding: 100px 220px 0 220px;
      width: 100%;
      height: 100%;
      font-size: 40px;
      display: flex;
      flex-direction: column;

      .zone {
        text-align: right;
        width: 100%;
        height: auto;

        img {
          max-height: 500px;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
        flex: 1;
        z-index: 10;

        .title {
          width: 80%;
          font-size: 200px;
          font-weight: 900;
          color: $magenta;
          padding-bottom: 60px;
          max-height: 600px;
          overflow: hidden;
        }

        .type {
          font-size: 100px;
          color: $magenta;
          padding-bottom: 60px;
          border-bottom: 28px solid $magenta;
          width: max-content;
          max-width: 100%;
        }

        .day {
          color: $white;
          font-size: 80px;
          padding-top: 80px;
          font-weight: 300;
        }

        .date {
          font-size: 140px;
          font-weight: 500;
          color: $white;
          padding: 10px 0 20px;
        }

        .time {
          font-size: 90px;
          color: $white;
          font-weight: 300;
        }

        .description {
          padding-top: 40px;
          font-size: 56px;
          color: $turquoise;
          font-weight: 300;
          max-height: 2100px;
          overflow: hidden;
          max-width: 1000px;
          line-height: 1.2;
          -webkit-mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
          mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
        }

        .presenter {
          display: flex;
          flex-direction: row;
          padding-top: 50px;
          align-items: flex-end;

          img {
            max-height: 400px;
          }
          span {
            font-size: 60px;
            align-self: flex-end;
            padding: 80px 40px;
            width: 50%;
          }
        }
      }

      .transparent-image {
        width: inherit;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        img {
          max-height: 2100px;
          max-width: 1300px;
          mask-image: radial-gradient(rgba(255,255,255,1) 30%, rgba(0, 0, 0, 0) 70%);
        }
      }
    }

    .no-current-events-page {
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .week-events-vertical-screen {
    height: inherit;
    min-height: inherit;
    width: inherit;
    min-width: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
    background: url("../../../_asset/img/dark-background.jpg")  $body-background;
    background-size: cover;
    overflow: hidden;
    /* this will hide the scrollbar in mozilla based browsers */
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    /* this will hide the scrollbar in internet explorers */
    -ms-overflow-style: none;

    .title {
      font-size: 200px;
      font-weight: 800;
      color: $magenta;
      text-align: left;
      width: 100%;
      padding: 150px 100px;
      display: flex;
      flex-direction: row;

      .magenta-logo {
        max-height: 500px;
        padding: 0 100px;
        width: 100%;
        max-width: 900px;

        img {
          height: 100%;
        }
      }
    }

    .wrapper {
      position: relative;

      .content {
        height: 2700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        overflow: hidden;
        width: 100%;

        .left-column {
          height: 2700px;
          width: 100%;
          padding: 0 100px;

          .event {
            display: flex;
            flex-direction: row;
            font-size: 80px;
            width: 100%;
            padding: 40px 0;
            border-bottom: 4px solid $magenta;

            &:last-child {
              border-bottom: none;
            }

            .day-date {
              border-right: 4px solid $magenta;
              padding: 25px 25px 25px 0;
              text-align: right;
              min-width: 300px;

              .day {
                font-size: 28px;
                font-weight: 300;
              }
              .date {
                font-size: 48px;
                font-weight: 700;
              }
            }

            .event-name-time {
              padding-right: 20px;
              flex-grow: 1;
              text-align: right;
              display: flex;
              flex-direction: column;

              .name-time {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                &:last-child {
                  flex-grow: 1;

                  .name {
                    padding: 0 25px 0 !important;
                  }
                }

                .name {
                  font-size: 44px;
                  font-weight: 400;
                  line-height: 44px;
                  border-right: 4px solid $magenta;
                  padding: 0 25px 20px;

                }

                .time {
                  font-size: 28px;
                  font-weight: 300;
                  line-height: 44px;
                  width: 160px;
                  min-width: 160px;
                  text-align: left;
                  padding-left: 35px;
                }
              }
            }

            .zone {
              width: 350px;
              min-width: 350px;
              display: flex;
              justify-content: center;
              align-self: center;
              img {
                width: 250px;
                height: auto;
              }
            }
          }
        }
      }
    }
    .changes-in-program {
      font-size: 34px;
      color: $magenta;
      position: absolute;
      bottom: 100px;
      right: 140px;
    }
  }
}
