.print-single-layout {
  height: 1920px;
  min-height: 1920px;
  width: 1080px;
  min-width: 1080px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: url("../../_asset/img/bg_lines.png") no-repeat $body-background;
  background-size: 100% 100%;
  overflow: hidden;
}

.print-week-layout {
  height: 100%;
  min-height: 1920px;
  width: 1080px;
  min-width: 1080px;
  display: flex;
  flex-direction: column;

  .week-events-print {
    height: 1920px;
    min-height: 1920px;
    width: 1080px;
    min-width: 1080px;
    background: url("../../_asset/img/dark-background.jpg") no-repeat $body-background;
    background-size: 100% 100%;
    overflow: hidden;
  }
}
