.main-event-screen {
  width: 3840px;
  min-width: 3840px;
  height: 2160px;
  min-height: 2160px;

  .week-events-horizontal-screen {
    height: inherit;
    min-height: inherit;
    width: inherit;
    min-width: inherit;
    background: url("../../../_asset/img/dark-background.jpg")  $body-background;
    background-size: cover;
    padding: 140px 100px 0 100px;
    position: relative;
    overflow: hidden;

    .title {
      font-size: 240px;
      font-weight: 900;
      color: $magenta;
      padding-bottom: 100px;
    }

    .wrapper {
      width: 100%;

      .content {
        .week-events-horizontal {
          display: flex;
          flex-direction: row;
        }

        .transition {
          position: absolute;
          top: 480px;
          width: calc(100% - 200px) !important;
          height: inherit;
        }

        .left-column, .right-column {
          height: 1300px;
          width: 50%;

          .event {
            display: flex;
            flex-direction: row;
            font-size: 80px;
            width: 100%;
            padding: 40px 0;
            border-bottom: 4px solid $magenta;

            &:last-child {
              border-bottom: none;
            }

            .day-date {
              border-right: 4px solid $magenta;
              padding: 20px 20px 20px 0;
              text-align: right;
              min-width: 300px;

              .day {
                font-size: 28px;
                font-weight: 300;
              }
              .date {
                font-size: 46px;
                font-weight: 700;
              }
            }

            .event-name-time {
              padding-right: 20px;
              flex-grow: 1;
              text-align: right;
              display: flex;
              flex-direction: column;

              .name-time {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                &:last-child {
                  flex-grow: 1;

                  .name {
                    padding: 0 25px 0 !important;
                  }
                }

                .name {
                  font-size: 44px;
                  font-weight: 400;
                  line-height: 44px;
                  border-right: 4px solid $magenta;
                  padding: 0 20px 20px;
                }

                .time {
                  font-size: 28px;
                  font-weight: 300;
                  line-height: 44px;
                  width: 160px;
                  min-width: 160px;
                  text-align: left;
                  padding-left: 15px;
                }
              }
            }

            .zone {
              width: 250px;
              min-width: 250px;
              display: flex;
              justify-content: center;
              align-self: center;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        .right-column {
          height: 1500px;
          padding-right: 0;
          padding-left: 80px;
        }
      }
    }
    .events-footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 50px;

      .magenta-logo {
        height: auto;
        width: 500px;
        position: absolute;
        left: 100px;
        bottom: 30px;
      }
    }
    .changes-in-program {
      font-size: 54px;
      font-weight: 300;
      color: $magenta;
      position: absolute;
      bottom: 140px;
      right: 140px;
    }
    .no-week-events {
      .no-events {
        .text {
          padding-bottom: 140px;
        }
      }
    }
  }

  .current-events-horizontal-screen {
    height: inherit;
    min-height: inherit;
    width: inherit;
    min-width: inherit;
    background: url("../../../_asset/img/bg_lines.png")  $body-background;
    background-size: cover;
    overflow: hidden;

    .no-current-events-page {
      height: 100%;
      justify-content: center;
    }

    .transition {
      width: inherit;
    }

    .single-event {
      padding: 240px 200px 0 200px;
      width: 100%;
      height: 100%;
      font-size: 40px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 10;

      .title-zone {
        display: flex;
        flex-direction: row;

        .title {
          width: 80%;
          font-size: 200px;
          font-weight: 900;
          color: $magenta;
          padding-bottom: 40px;
          max-height: 400px;
          overflow: hidden;
        }
        .zone {
          text-align: right;
          width: 20%;
          height: 240px;
        }
      }

      .columns {
        display: flex;
        flex: 1;

        .left-column {
          width: 60%;
          display: flex;
          flex-direction: column;
          flex: 1;
          //justify-content: space-between;

          .type {
            font-size: 100px;
            color: $magenta;
            padding-bottom: 40px;
            border-bottom: 28px solid $magenta;
            width: max-content;
            max-width: 100%;
          }

          .day {
            color: $white;
            font-size: 80px;
            padding-top: 40px;
            font-weight: 300;
          }

          .date {
            font-size: 140px;
            font-weight: 500;
            color: $white;
            padding: 10px 0 20px;
          }

          .time {
            font-size: 90px;
            color: $white;
            font-weight: 300;
          }

          .description {
            padding-top: 40px;
            font-size: 56px;
            color: $turquoise;
            font-weight: 300;
            width: 60%;
            max-height: 930px;
            overflow: hidden;
            -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
            mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
          }

          .presenter {
            display: flex;
            flex-direction: row;
            padding-top: 50px;
            align-items: flex-end;

            img {
              max-height: 400px;
            }
            span {
              font-size: 60px;
              align-self: flex-end;
              padding: 80px 40px;
              width: 50%;
            }
          }
        }
      }
    }
    .transparent-image {
      position: absolute;
      width: 50%;
      left: 50%;
      top: 0;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      img {
        width: auto;
        height: auto;
        max-width: 1800px;
        max-height: 1500px;

      }
    }
  }
  .transition {
    width: 100%;
    height: inherit;
  }
}
