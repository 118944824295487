.single-event {
  padding: 240px 200px 0 200px;
  width: 100%;
  height: 100%;
  font-size: 40px;
  display: flex;
  flex-direction: column;

  .title-zone {
    display: flex;
    flex-direction: row;

    .title {
      width: 80%;
      font-size: 200px;
      font-weight: 900;
      color: $magenta;
      padding-bottom: 40px;
      max-height: 400px;
      overflow: hidden;
    }
    .zone {
      text-align: right;
      width: 20%;
      height: 240px;
    }
  }

  .columns {
    display: flex;
    flex: 1;

    .left-column {
      width: 60%;
      display: flex;
      flex-direction: column;
      flex: 1;
      //justify-content: space-between;

      .type {
        font-size: 100px;
        color: $magenta;
        padding-bottom: 40px;
        border-bottom: 28px solid $magenta;
        width: max-content;
        max-width: 100%;
      }

      .day {
        color: $white;
        font-size: 80px;
        padding-top: 40px;
        font-weight: 300;
      }

      .date {
        font-size: 140px;
        font-weight: 500;
        color: $white;
        padding: 10px 0 20px;
      }

      .time {
        font-size: 90px;
        color: $white;
        font-weight: 300;
      }

      .description {
        padding-top: 40px;
        font-size: 56px;
        color: $turquoise;
        font-weight: 300;
        width: 80%;
        max-height: 480px;
        overflow: hidden;
      }

      .presenter {
        display: flex;
        flex-direction: row;
        padding-top: 50px;
        align-items: flex-end;

        img {
          max-height: 400px;
        }
        span {
          font-size: 60px;
          align-self: flex-end;
          padding: 80px 40px;
          width: 50%;
        }
      }
    }

    .right-column {
      width: 40%;

      .transparent-image {
        width: inherit;
        top: 40%;
        right: 5%;
        z-index: 1;
      }
    }
  }
}
